<template>
    <div>
        <div class="bet_cart_section">
            <div class="bet_cart">
                <div class="info_bet">
                    <div class="info_box">
                        <div class="game01">
                            <div class="txt1 text-skyblue">
                                <slot></slot>
                                선택
                            </div>
                            <div class="txt2 text-skyblue">{{selectedInfo.selectText}}</div>
                        </div>
                        <div class="game01">
                            <div class="txt1">보유금액</div>
                            <div class="txt2">{{$store.state.userInfo.cash|comma}}원</div>
                        </div>
                        <div class="game01">
                            <div class="txt1">배당</div>
                            <div class="txt2">{{selectedInfo.seletedOdd}}</div>
                        </div>
                        <div class="game01">
                            <div class="txt1">적중금액</div>
                            <div class="txt2">{{getWinCash|comma}}원</div>
                        </div>
                    </div>
                    <div class="btn_box">
                        <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(5000)">5천</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(10000)">1만</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(50000)">5만</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(100000)">10만</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(300000)">30만</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(500000)">50만</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(1000000)">100만</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="maxCash()">MAX</button>
                        <button type="button" class="btn btn_minigame_addcash" @click="initBetcash">금액초기화</button>
                    </div>
                    <div class="bet_money">
                        <input type="text" v-model="betCash" @input="setBetCahsComma">
                    </div>
                    <div class="betnow">
                        <button type="button" @click="doBet">배팅하기</button>
                    </div>

                </div>
            </div>
        </div>

    </div>


</template>

<script>
    import {doLeisureBet} from "../../network/leisureRequest";
    import {RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";
    import {checkLoginMinxin} from "../../common/mixin";
    import sportsConst from "../../common/sportsConst";

    export default {
        name: "LeisureBetCartComp",
        mixins: [checkLoginMinxin],
        props: {
            kind: {
                type: Number,
                default() {
                    return 0
                }
            },
            selectedInfo: {
                selectedAttr: 0,
                selectedGameId: 0,
                selectedWay: 0,
                seletedOdd: 0,
                selectedText: '',
                config: {maxBetCash: 0, minBetCash: 1000, maxWinCash: 0}
            }
        },
        data() {
            return {
                isFirst: true,
                winCash: 0,
                betCash: '',
                betInfo: {attribute: 0, way: 0, gameId: 0, kind: 0, betCash: 0},
                betConfig:null,
            }
        },
        computed: {
            getWinCash() {
                if (this.betCash != '') {
                    this.winCash = Math.round(this.getCleanBetCash * this.selectedInfo.seletedOdd);
                } else {
                    this.winCash = 0
                }
                return this.winCash

            },
            getCleanBetCash() {
                if (this.betCash == '') return 0
                return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
            },
        },
        methods: {
            /*버튼 올인*/
            allInCash() {
                this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
            },
            /*버튼 초기화*/
            initBetcash() {
                this.betCash = ''
            },
            /*버튼 캐쉬클릭 ++*/
            cashAdd(amount) {
                if (this.betCash == '') {
                    this.betCash = 0;
                }
                this.betCash = this.$root.$options.filters.comma(this.getCleanBetCash + amount);
            },
            setBetCahsComma() {
                this.betCash = this.$root.$options.filters.comma(this.betCash)
            },
            maxCash() {
                if (this.selectedInfo.seletedOdd <= 1) {
                    this.$swal({
                        title: '베팅하실 게임을 선택하세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
                if(this.getCleanBetCash > this.betConfig.maxAmount){
                    this.betCash = this.$root.$options.filters.comma(this.betConfig.maxAmount)
                }
            },
            /*베팅하기*/
            doBet() {
                if (!this.checkLogin()) {
                    return false
                }
                this.$swal({
                    title: this.$root.$options.filters.comma(this.betCash) + '을 베팅하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        if (this.selectedInfo.selectedGameId === 0
                            || this.selectedInfo.selectedAttr === 0
                            || this.selectedInfo.selectedWay === 0
                            || this.selectedInfo.seletedOdd === 0) {
                            this.$swal({
                                title: '베팅하실 경기를 선택하세요',
                                type: 'error',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                            return false
                        }
                        // if (this.getCleanBetCash > this.selectedInfo.config.maxBetCash) {
                        //     this.$swal({
                        //         title: '최대 배팅금액은 ' + (this.selectedInfo.config.maxBetCash) + '원입니다',
                        //         type: 'error',
                        //         showCancelButton: false,
                        //         showConfirmButton: true
                        //     })
                        //     this.betCash = this.$root.$options.filters.comma(this.selectedInfo.config.maxBetCash)
                        //     return false
                        // }
                        // if (this.getCleanBetCash < this.selectedInfo.config.minBetCash) {
                        //     this.$swal({
                        //         title: '최소 배팅금액은 ' + (this.selectedInfo.config.minBetCash) + '원입니다',
                        //         type: 'error',
                        //         showCancelButton: false,
                        //         showConfirmButton: true
                        //     })
                        //     this.betCash = this.$root.$options.filters.comma(this.selectedInfo.config.minBetCash)
                        //     return false
                        // }
                        // if (this.getWinCash > this.selectedInfo.config.maxWinCash) {
                        //     this.$swal({
                        //         title: '최대 당첨금액은 ' + (this.selectedInfo.config.maxWinCash) + '원입니다',
                        //         type: 'error',
                        //         showCancelButton: false,
                        //         showConfirmButton: true
                        //     })
                        //     this.betCash = this.selectedInfo.config.minBetCash
                        //     return false
                        // }

                        //{attribute: 20, way: 2, gameId: 481007, kind: 2, betCash: 6000}
                        this.betInfo.kind = this.kind
                        this.betInfo.attribute = this.selectedInfo.selectedAttr
                        this.betInfo.way = this.selectedInfo.selectedWay
                        this.betInfo.gameId = this.selectedInfo.selectedGameId
                        this.betInfo.betCash = this.getCleanBetCash
                        doLeisureBet(this.betInfo).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '베팅성공',
                                    type: 'success',
                                    confirmButtonText: '확인',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.betCash = ''
                                //베팅성공시 회원정보를 init
                                //UserInfoComp.vue에서 모니터
                                this.$store.dispatch('actionUserInfo')
                                //선택초기화
                                this.$bus.$emit('leisureBetSuccess')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                // setTimeout(() => {
                                //   this.$router.go(0)
                                // }, 1500)
                            }
                        })
                    }
                })
            },
        },
        created() {
            this.betCash = ''
            let configList = this.$store.state.userInfo.rank.leisureBetByRankConfigList;
            configList.map(c=>{
                if(c.kindId == this.kind){
                    this.betConfig = c;
                }
            })

        },
        watch: {
            "selectedInfo": {
                deep: true,
                handler: function (newValue) {
                    //console.log(newValue)
                }
            },
        }
    }
</script>

<style scoped>

</style>